import React, {useEffect, useMemo, useState} from "react";
import _ from "lodash";
import styled from "styled-components";
import {CaretDownOutlined, CaretUpOutlined} from "@ant-design/icons/lib";
import {connect, useSelector} from "react-redux";
import {addMenuKey} from "./MenuFrame";
import {getCanvasWidth} from "../../selectors/editorSelectors";
import { getTenant } from "utils/localStorage";
import Api from "../../api/Api";

const HeadBox = styled.section<{
  backgroundColor: string,
  position: string,
  height: string,
  leftWidth: number,
  canvasWidth: number,
  headBoxZIndex:number,
}>`
  display: flex;
  align-items: center;
  background-color: ${({backgroundColor}) => backgroundColor || '#f4f4f4'};
  position: relative;
  z-index:  ${({headBoxZIndex}) => headBoxZIndex};
  height: ${({height}) => height || 60}px;
  min-height: 60px;
  box-sizing: content-box;
  left: 0;
  top: 0;
  right: 0;
  min-width: ${({leftWidth, canvasWidth}) => (canvasWidth+leftWidth)}px;
`
const TopMenuBox = styled.section<{
  align: string
}>`
  text-align: ${({align}) => align};
  height: inherit;
`;
const TopMenuWrapper = styled.section<{
  width: string,
  height: string,
  backgroundColor: string,
  borderColor: string,
  borderSolid: string,
  borderWidth: string,
  fontColor: string,
  fontSize: string,
  fontWeight: string,
  align: string,
  position: string,
  isBgColor: boolean,
  isBorder: boolean,
  selectBackgroundColor: string,
  selectBorderColor: string,
  selectBorderSolid: string,
  selectBorderWidth: string,
  selectFontColor: string,
  selectFontSize: string,
  selectFontWeight: string,
  selectIsBgColor: boolean,
  selectIsBorder: boolean
}>`
  color: ${({fontColor}) => fontColor};
  font-weight: ${({fontWeight}) => fontWeight};
  font-size: ${({fontSize}) => `${fontSize}px`};
  position:relative;
  display:inline-block;
  z-index: 2;
  height: inherit;
  .menu-span{
    text-align: center;
    display:block;
    cursor:pointer;
    padding: 4px 10px;
  }
  .menu-span-focus{
    background-color: ${({selectBackgroundColor, selectIsBgColor}) => selectIsBgColor && selectBackgroundColor};
    border: ${
  ({selectBorderWidth, selectBorderSolid, selectBorderColor, selectIsBorder}) =>
    selectIsBorder && `${selectBorderWidth}px ${selectBorderSolid || 'solid'} ${selectBorderColor}`
};
    color: ${({selectFontColor}) => selectFontColor};
    font-weight: ${({selectFontWeight}) => selectFontWeight};
  }
  & > ul > li > .menu-span{
    padding: 0;
    width: 100%;
  }
  & > ul{
    display: flex;
    height: inherit;
    li{
      position:relative;
      min-width: 100px;
      display: flex;
      align-items: center;
      ul{
        display:none;
        .menu-span:hover{
          color:#fff;
          background-color:#f00;
        }
        li{
          display: block;
        }
      }
      &:hover > ul{
        display:block;
      }
    }
  }
  & > ul ul{
    position:absolute;
    left: 0;
    top: ${({height}) => height || 60}px;
    color: #333;
    background:#fff;
    box-shadow: 0 0 5px rgba(0,0,0,.2);
    ul{
      left: 100%;
      top: 0;
    }
  }
`;
const LeftMenuWrapper = styled.section<{
  backgroundColor: string,
  borderColor: string,
  borderSolid: string,
  borderWidth: string,
  childBackgroundColor: string,
  childFontColor: string,
  childFontSize: string,
  childFontWeight: string,
  childIsBgColor: boolean,
  childWidth: string,
  fontColor: string,
  fontSize: string,
  fontWeight: string,
  height: string,
  isBgColor: boolean,
  isBorder: boolean,
  selectBackgroundColor: string,
  selectBorderColor: string,
  selectBorderWidth: string,
  selectBorderSolid: string,
  selectFontColor: string,
  selectFontSize: string,
  selectFontWeight: string,
  selectIsBgColor: boolean,
  selectIsBorder: boolean,
  width: string
}>`
  font-size: ${({fontSize}) => fontSize}px;
  color: ${({fontColor}) => fontColor};
  font-weight: ${({fontWeight}) => fontWeight};
  background-color: ${({backgroundColor, isBgColor}) => isBgColor && backgroundColor};
  border-right: 1px solid #f4f4f4;
  height: 100%;
  li{
    ul{
      background-color: ${({childBackgroundColor, childIsBgColor}) => childIsBgColor && childBackgroundColor};
      font-size: ${({childFontSize}) => `${childFontSize}px`};
      color: ${({childFontColor}) => childFontColor};
      font-weight: ${({childFontWeight}) => childFontWeight};
      .menu-span{
        height: ${({childWidth}) => `${childWidth}px`};
        line-height: ${({childWidth}) => `${childWidth}px`};
      }
    }
  }
  .menu-span{
    height: ${({height}) => `${height}px`};
    line-height: ${({height}) => `${height}px`};
    display:block;
    padding:10px;
    box-sizing: content-box;
    flex: 1;
  }
  .menu-span-focus{
    background-color: ${({selectBackgroundColor, selectIsBgColor}) => selectIsBgColor && selectBackgroundColor};
    color: ${({selectFontColor}) => selectFontColor};
    font-size: ${({selectFontSize}) => `${selectFontSize}px`};
    font-weight: ${({selectFontWeight}) => selectFontWeight};
  }
  .x-menu-item{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .x-menu-arrow{
    margin-right: 10px;
  }
`;
function reMenu(data: any) {
  data.sort((a: any, b: any) => a.sort - b.sort);
  let arr: any = [];
  if (!data) {
    return [];
  }
  let fn = (arr1: any, arr2: any) => {
    _.each(arr1, (i: any, idx: number) => {
      arr2.push({
        ...i,
        label: i.title,
        actionType: i.actionType==='toUrl'?'toUrl':'toPage',
        appId: i.appid||i.appId,
        toPageId: i.topageid||i.toPageId,
        toPage: i.topage||i.toPage,
        toPageType: i.topagetype||i.toPageType,
        children: _.isEmpty(i.children) ? null : []
      })
      if (!_.isEmpty(i.children)) {
        fn(i.children, arr2[idx]?.children);
      }
    })
  };
  fn(data, arr);
  return arr;
}


function TopMenuChildren(props: any) {
  const {data, menuFocusKey, setMenuFocusKey, itemClick} = props;
  const menuItemClick = (i:any)=>{
    if(
      (i.actionType === 'toPage'&&i.toPageId)
      ||(i.actionType === 'toUrl'&&i.toUrl)
      ||i.readonly
    ){
      setMenuFocusKey(i.menuKey);
      itemClick&&itemClick(i);
    }
  }
  return (
    <ul>
      {
        data.map((i: any) => {
          return (
            <li style={{cursor: 'pointer'}} key={i.menuKey}
                className={menuFocusKey.includes(i.menuKey)?'menu-span-focus':''}
            >
              <span className={`menu-span `} onClick={()=>{
                menuItemClick(i);
              }}>{i.title}</span>
              {
                i.children && i.children.length > 0 ? (
                  <TopMenuChildren data={i.children} level={true} menuFocusKey={menuFocusKey}
                                   setMenuFocusKey={setMenuFocusKey} itemClick={itemClick} />
                ) : null
              }
            </li>
          )
        })
      }
    </ul>
  )
}
export function getAllParentIds(tree:any, targetMenuKey:any, parentIds:Array<string> = [], key:string='menuKey') {
  for (const node of tree) {
    if (node[key] == targetMenuKey) {
      return _.isEmpty(parentIds)?[node.menuKey]:parentIds;
    }
    if (node.children && node.children.length > 0) {
      const parentsHere = [...parentIds, node.menuKey];
      const result:Array<string> = getAllParentIds(node.children, targetMenuKey, parentsHere);
      if (result.length > 0){
        return result;
      }
    }
  }
  return [];
}
export function TopMenu(props: any) {
  const {
    itemClick,
    initIframe,
    menuFocusKey:parentMenuFocusKey,
    setMenuFocusKey:parentSetMenuFocusKey
  } = props;
  const {menus, topForm, menusType} = initIframe;
  const [menuFocusKey, setMenuFocusKey] = useState<Array<string>>([]);
  const [newTree, setNewTree] = useState([]);
  const setMenuFocusKeyFn = (menuKey:any)=>{
    const allMenuKey = getAllParentIds(menus, menuKey, [menuKey]);
    parentSetMenuFocusKey?parentSetMenuFocusKey(allMenuKey):setMenuFocusKey(allMenuKey);
  }
  useEffect(()=>{
    let _newTree = reMenu(menus);
    if(menusType === 2){
      _newTree = _newTree.map((i:any)=>{
        return {
          ...i,
          children: null
        }
      })
    }
    setNewTree(_newTree);
  }, [initIframe])
  return (
    <TopMenuBox align={topForm.top?.align ?? 'left'}>
      <TopMenuWrapper {...topForm.top}>
        <TopMenuChildren data={newTree} menuFocusKey={parentMenuFocusKey||menuFocusKey} setMenuFocusKey={setMenuFocusKeyFn}
                         itemClick={itemClick}/>
      </TopMenuWrapper>
    </TopMenuBox>
  )
}

function LeftMenuChildren(props: any) {
  const {data, index, menuFocusKey, setMenuFocusKey, itemClick} = props;
  const [status, setStatus] = useState<Record<string, boolean>>({});
  const showChild = (key:string)=>{
    setStatus({
      ...status,
      [key]: !status[key]
    })
  }
  const menuItemClick = (i:any)=>{
    if(i.children){
      showChild(i.menuKey);
    }else{
      if(
        (i.actionType === 'toPage'&&i.toPageId)
        ||(i.actionType === 'toUrl'&&i.toUrl)
        ||i.readonly
      ){
        setMenuFocusKey(i.menuKey)
        itemClick&&itemClick(i);
      }
    }
  }

  return (
    <ul>
      {
        data.map((i: any) => {
          let showChildrenFlag = status[i.menuKey] || menuFocusKey.includes(i.menuKey);
          return (
            <li key={i.menuKey}>
              <div className={`x-menu-item`}
              onClick={()=>{
                menuItemClick(i);
              }}>
                <span className={!i.children&&menuFocusKey.includes(i.menuKey)?'menu-span menu-span-focus':'menu-span'}
                      style={{paddingLeft: `${index * 10}px`, cursor: ((i.readonly)||(i.actionType === 'toPage'&&i.toPageId)||(i.actionType === 'toUrl'&&i.toUrl)||i.children)?'pointer':'default'}}>{i.title}</span>
                {
                  i.children &&
                  (<span className={`x-menu-arrow`}>
                    {
                      showChildrenFlag ? <CaretDownOutlined/> : <CaretUpOutlined/>
                    }
                  </span>)
                }
              </div>
              {
                showChildrenFlag && i.children && i.children.length > 0 ? (
                  <LeftMenuChildren index={index + 1} data={i.children}
                                    menuFocusKey={menuFocusKey}
                                    setMenuFocusKey={setMenuFocusKey} itemClick={itemClick} />
                ) : null
              }
            </li>
          )
        })
      }
    </ul>
  )
}
export function LeftMenu(props: any) {
  const {
    initIframe,
    itemClick,
    menuFocusKey:parentMenuFocusKey,
    setMenuFocusKey:parentSetMenuFocusKey
  } = props;
  const {menus, leftForm, menusType} = initIframe;
  const [menuFocusKey, setMenuFocusKey] = useState<Array<string>>([]);
  const [newTree, setNewTree] = useState([]);
  const setMenuFocusKeyFn = (menuKey:any)=>{
    const allMenuKey = getAllParentIds(menus, menuKey, [menuKey]);
    parentSetMenuFocusKey?parentSetMenuFocusKey(allMenuKey):setMenuFocusKey(allMenuKey);
  }
  useEffect(() => {
    let _newTree = reMenu(menus);
    if(menusType === 2){
      let singleMenu = _newTree.filter((i:any)=>{
        return (parentMenuFocusKey||[]).includes(i.menuKey);
      })
      if(!_.isEmpty(singleMenu)){
        _newTree = singleMenu[0].children??[];
      }
    }
    setNewTree(_newTree);
  }, [initIframe, parentMenuFocusKey]);

  return (
    <LeftMenuWrapper {...leftForm.left}>
      {
        (newTree && newTree.length > 0)?
          (
            <div style={{
              width: `${initIframe?.leftForm?.left?.width??'200'}px`,
              minWidth: '200px',
              position: 'relative',
              zIndex: '1',
              height: `100%`,
              backgroundColor: initIframe.leftForm?.left?.backgroundColor||'#f4f4f4'
            }}>
              <LeftMenuChildren index={1} data={newTree} menuFocusKey={parentMenuFocusKey||menuFocusKey} setMenuFocusKey={setMenuFocusKeyFn} itemClick={itemClick} />
            </div>
          )
          : null
      }
    </LeftMenuWrapper>
  );
}

function AppEditMenu(props: any) {
  const {
    children,
    editIframeDSL,
    reducersPageLists,
    headBoxZIndex,
    appId
  } = props;
  const [menuFocusKey, setMenuFocusKey] = useState<Array<string>>(['systemmana']);
  const canvasWidth = useSelector(getCanvasWidth);

  const [iframeDSL, setIframeDSL] = useState<{
    menusType: number,
    menus: any[],
    topForm: any,
    leftForm: any,
    logo: string
  }>({
    menus: [],
    topForm: {},
    leftForm: {},
    menusType: 4,
    logo: ''
  });
  const getData = () => {
    let _iframeDSL: any = _.find(reducersPageLists, {slug: "frameDesignPage"})?.['layouts'][0]['dsl'];
    if(!_.isEmpty(editIframeDSL)){
      _iframeDSL = editIframeDSL;
    }

    if(_.isEmpty(_iframeDSL.menus)&&_.isEmpty(editIframeDSL)){
      _iframeDSL = {...iframeDSL};
      Api.get(`/byk/platform/app/appMenu`, {
        appId: appId
      }).then((res:any)=>{
        let _editMenu = res.result;
        _iframeDSL.menus = addMenuKey(_editMenu).filter((i:any)=>{
          return i.menuKey !== 'other';
        });
        _iframeDSL.menusType = 0;
        setIframeDSL(_iframeDSL);
      })
    }else{
      let _editMenu = _iframeDSL.menus;
      _iframeDSL.menus = addMenuKey(_editMenu).filter((i:any)=>{
        return i.menuKey !== 'other';
      });
      setIframeDSL(_iframeDSL);
    }
  }
  const leftWidth = useMemo(()=>{
    let _leftW = iframeDSL?.leftForm?.left?.width || 200;
    if(_.isString(_leftW)){
      _leftW = parseInt(_leftW, 10)
    }
    if(iframeDSL.menusType == 1){
      _leftW = 0;
    }
    return _leftW;
  }, [iframeDSL])

  useEffect(() => {
    getData();
  }, [editIframeDSL, reducersPageLists]);
  return (
    <>
      <HeadBox backgroundColor={iframeDSL?.topForm?.top?.isBgColor ? (iframeDSL?.topForm?.top?.backgroundColor) : ''}
               position={iframeDSL?.topForm?.top?.position}
               headBoxZIndex={headBoxZIndex}
               height={iframeDSL?.topForm?.top?.height} leftWidth={leftWidth} canvasWidth={canvasWidth}>
        <div style={{width: `${iframeDSL.logo?(leftWidth==0?100:leftWidth):0}px`, padding: '0 10px', boxSizing: 'border-box'}}>
          {
            iframeDSL.logo &&
            <img style={{width: '100%', height: '60px', margin: '0 auto', objectFit: 'contain'}}
                 src={`/byk/${getTenant()}/file/${iframeDSL.logo}`}
                 alt=""/>
          }
        </div>
        <div style={{flex: 1, height: '100%'}}>
          {
            [1, 2].includes(iframeDSL.menusType) &&
            <TopMenu initIframe={iframeDSL} menuFocusKey={menuFocusKey} setMenuFocusKey={setMenuFocusKey} />
          }
        </div>
      </HeadBox>
      <div style={{width: '100%', position: 'relative', flex:1}}>
        <div style={{position: 'absolute', left: 0, top: 0, bottom: 0, width: `${leftWidth}px`}}>
          {
            [0, 2].includes(iframeDSL.menusType) && (
              <LeftMenu initIframe={iframeDSL} menuFocusKey={menuFocusKey} setMenuFocusKey={setMenuFocusKey} />
            )
          }
        </div>
        <div style={{width: `${canvasWidth+leftWidth}px`, paddingLeft: `${leftWidth}px`}}>
          {children}
        </div>

      </div>
    </>
  )
}

const mapStateToProps = (state: any) => ({
  reducersPageLists: state.entities.pageList.pages,
  editIframeDSL: state.XReducers.iframeDSL,
  headBoxZIndex: state.XReducers.headBoxZIndex,
  appId: state.entities.pageList.applicationId
})

export default connect(mapStateToProps, null)(AppEditMenu);
