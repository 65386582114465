import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Cascader, Collapse, Empty, Form, Input, Select} from "antd";
import _ from "lodash";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CaretDownOutlined,
  CaretRightOutlined,
  CloseCircleFilled,
  MinusCircleOutlined,
  PlusCircleOutlined
} from "@ant-design/icons/lib";
import cursorImage from './cursor-fill@2x.png';
import {X_MYTASK_MAPPING, X_MYTASK_MAPPING_STATUS} from "@byk/store/XReducers";
import {useDispatch, useSelector} from "react-redux";
import XCodeMirror from "@byk/pages/MyTask/XCodeEdit";

const {Panel} = Collapse;
const FormWrapper = styled.div<{
  dir: string
}>`
  .ant-form-item{
    margin-bottom: 0!important;
  }
  .ant-form-item-label>label{
    height: 36px!important;
    word-break: break-all;
  }
  .ant-select:not(.ant-select-customize-input) .ant-select-selector{
    border-top-left-radius: 5px!important;
    border-bottom-left-radius: 5px!important;
    border-top-right-radius: 0px!important;
    border-bottom-right-radius: 0px!important;
  }

  .ant-select-selector {
    height: 36px!important;
    .ant-select-selection-item{
      line-height: 36px!important;
    }
  }

  .mappingSelector {
    width: 100px!important;
    display: ${({dir}) => dir === 'out' ? 'none' : 'block'};
  }

  .subTbAttrWrapper {
     background-color: rgba(249, 249, 249, 1);

    .ant-select-selector {
      width: 290px!important;
      border-radius: 5px!important;
    }

    .ant-select:not(.ant-select-customize-input) .ant-select-selector{
      border-radius: 5px!important;
    }

    .subTbAttr {
      width: ${({dir}) => dir === 'out' ? '292px' : '309px'} !important;
      .ant-select-selector {
        width: ${({dir}) => dir === 'out' ? '292px' : '309px'} !important;
      }
    }
  }

  .ant-input-focused, .ant-input:focus{
    box-shadow: none!important;
  }
  .anticon-plus-circle, .anticon-minus-circle{
    color: #006cf2;
    margin-left: 10px;
  }
  .x-input-focus{
    box-shadow: 0 0 5px rgba(255, 155, 78, 1);
  }
  .codeMirrorWrapper{
    width: 200px;
    height: 36px;
    flex: 1;
    border: 1px solid #d9d9d9;
    ${({dir}) => dir === 'out' ? `
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    ` : `
      border-left: none;
    `};

    position: relative;
    display: flex;
    &,.x-cursor-box{
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
    .ant-input{
      border: none!important;
    }
    .CodeMirror-code{
      margin-top: 4px!important;
    }
    .x-cursor-box{
      padding: 8px;
      background: #fff;
      .cursor{
        width: 20px;
        height: 20px;
        max-width: 20px;
        display: block;
      }
    }
  }
  .anticon-arrow-left, .anticon-arrow-right{
    color: #006cf2;
    margin: 0 10px;
  }
  .ant-input[disabled]{
    color: #333!important;
    background-color: #f5f7f9!important;
  }
  input::placeholder {
    color: #999!important;
  }
  .clearIcon{
    display: none;
  }
  .codeMirrorWrapper:hover .clearIcon{
    display: block;
  }

  .subTbOffOn {
    position: absolute;
    left: -190px;
    cursor: pointer;
  }
`;

interface MappingEditProp {
  title: string,
  service: string,
  mapping: any[],
  dir: string,
  isAdd?: boolean,
  cacheSaveParams?: (key: string, value: any) => void
}

const EmptyComponent = (props: { des: string }) => (
  <Empty
    image={Empty.PRESENTED_IMAGE_SIMPLE}
    description={
      <span style={{fontSize: '14px', color: '#9c9c9c'}}>
        {props.des || '暂无数据'}
      </span>
    }
  />
);

function changeCursor(str: string) {
  document.body.style.cursor = str || 'default';
}

function MappingEdit(props: MappingEditProp) {
  const {
    title,
    service,
    mapping,
    dir,
    isAdd,
    cacheSaveParams
  } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [subTbSExpand, setSubTbSExpand] = useState<any>({});
  const myTaskMappingState = useSelector((state: any) => {
    return state.XReducers.myTaskMapping;
  });
  const myTaskMappingStatus = useSelector((state: any) => {
    return state.XReducers.myTaskMappingStatus;
  });
  const propList = useSelector((state: any) => {
    return state.XReducers.widgetPropPopup.propList;
  });
  const Header = () => {
    return <strong>{title}</strong>;
  }
  const RightText = () => {
    return <span>收起</span>
  }
  const MappingIcon = () => {
    return dir == 'in' ? <ArrowLeftOutlined/> : <ArrowRightOutlined/>
  }
  const typeOptions = [
    {label: '页面元素', value: '__page'},
    {label: '上下文', value: '__context'},
    {label: '常量', value: '__constant'},
  ];

  const dispatchMyTaskMapping = (paylod: any) => {
    dispatch({
      type: X_MYTASK_MAPPING,
      data: {
        ...paylod
      }
    })
  }
  const dispatchMyTaskMappingStatus = (paylod: boolean) => {
    dispatch({
      type: X_MYTASK_MAPPING_STATUS,
      data: paylod
    })
  }
  //触发选择状态
  const clickLock = (str: string, path: string) => {
    changeCursor(str);
    dispatchMyTaskMappingStatus(true);
    dispatchMyTaskMapping({
      key: dir,
      path,
      isChecked: false
    })
  }
  const formItemLayout = {
    labelCol: {
      xs: {span: 24},
      sm: {span: 8},
    },
    wrapperCol: {
      xs: {span: 24},
      sm: {span: 16},
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: {span: 24, offset: 0},
      sm: {span: 16, offset: 8},
    },
  };
  const onFinish = (value: any, values: any) => {
    cacheSaveParams && cacheSaveParams(dir, values);
  };

  const getSubTableAttrOptions = (fieldName: any, index: number) => {
    try {
      let options: any = [];
      let values = form.getFieldValue(fieldName);
      let widgetId = '';
      if (values && values.splice && values.length > index) {
        widgetId = values[index]['pageValue'] || null;
      }

      if (widgetId) {
        let predicate: any = {
          type: 'SUB_TABLE_WIDGET',
          widgetId: widgetId,
        }

        let widget: any = _.find(propList, predicate)
        if (widget) {
          propList.filter((i: any) => {
            if (i.pid == widgetId && i.type == 'SUB_TABLE_WIDGET_COLUMNS' ) {
              // let isSelect = i.columnType == 'select';
              // let children = isSelect ? [
              //   {value: 'label',label: '标签'},
              //   {value: 'value',label: '值'},
              // ] : [];
              options.push({
                label: i.label,
                value: i.key,
                id: i.widgetId,
                isLeaf: true,
                children: null,
              });
            }
          })
        }
      }
      return options;
    } catch (e) {
    }
  }

  const subTbExpandOffOn = (key: any) => {
    let _subTbSExpand = {...subTbSExpand};
    _subTbSExpand[key] = !_subTbSExpand[key];
    setSubTbSExpand({..._subTbSExpand});
  }

  const DataList = () => {
    return (
      <FormWrapper dir={dir}>
        <Form form={form} onValuesChange={onFinish} labelWrap autoComplete="off" {...formItemLayoutWithOutLabel}>
          {
            mapping.map((i: any) => {
              let subTb = !!(i.childrenNode && i.childrenNode.length && i.childrenNode.length > 0);
              let labelKey = `${dir}_${i.value}`;
              return (
                <Form.List name={`${dir}_${i.value}`} key={`${dir}_${i.value}`}>
                  {(fields, {add, remove}) => (
                    <>
                      {fields.map(({key, name, ...restField}) => {
                        let subTbExpandOffOnKey = labelKey + '_' + key;
                        let isOpenSubTab = subTbSExpand[subTbExpandOffOnKey];
                        return (
                          <>
                            <Form.Item label={name == 0 ? i.label : ''}
                                       key={key} {...(name === 0 ? formItemLayout : formItemLayoutWithOutLabel)}>
                              <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                {subTb && <div className='subTbOffOn' onClick={() => {
                                  subTbExpandOffOn(subTbExpandOffOnKey);
                                }}
                                >
                                  {isOpenSubTab ? <CaretDownOutlined/> : <CaretRightOutlined/>}
                                </div>}
                                <MappingIcon/>
                                <Form.Item
                                  {...restField}
                                  name={[name, 'type']}
                                >
                                  <Select className='mappingSelector' disabled={dir === 'out'} options={typeOptions}/>
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  shouldUpdate
                                >
                                  {({getFieldValue}) => (
                                    <div
                                      className={`codeMirrorWrapper ${(`${myTaskMappingState.path}` == `${dir}_${i.value}.${name}`) && myTaskMappingStatus ? 'x-input-focus' : ''}`}
                                      style={{backgroundColor: getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__page' ? '#f4f4f4' : '#fff'}}
                                    >
                                      {
                                        getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__page' &&
                                        getFieldValue([`${dir}_${i.value}`, name, 'pageLabel']) != '' &&
                                        <div className={`clearIcon`}
                                             style={{
                                               position: 'absolute',
                                               zIndex: 9,
                                               right: '38px',
                                               top: '6px',
                                               color: '#ccc',
                                               cursor: 'pointer'
                                             }} onClick={
                                          () => {
                                            clearFormPage(`${dir}_${i.value}.${name}`);
                                          }
                                        }>
                                          <CloseCircleFilled/>
                                        </div>
                                      }
                                      <Form.Item style={{
                                        width: '100%'
                                      }} name={[name, 'contextValue']}>
                                        {
                                          getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__context' ?
                                            <XCodeMirror key={`${dir}_${name}`}/> : null
                                        }
                                      </Form.Item>
                                      <Form.Item name={[name, 'pageLabel']} style={{
                                        position: 'absolute',
                                        left: 0,
                                        right: '36px',
                                        top: 0,
                                        display: getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__page' ? 'block' : 'none'
                                      }}>
                                        <Input placeholder={`请点击箭头选择页面元素`}
                                               disabled={getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__page'}/>
                                      </Form.Item>
                                      <Form.Item name={[name, 'constantValue']} style={{
                                        position: 'absolute',
                                        left: 0,
                                        right: 0,
                                        top: 0,
                                        display: getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__constant' ? 'block' : 'none'
                                      }}>
                                        <Input/>
                                      </Form.Item>
                                      <Form.Item noStyle shouldUpdate>
                                        {
                                          getFieldValue([`${dir}_${i.value}`, name, 'type']) == '__page' ?
                                            <div className={`x-cursor-box`}><img className={`cursor`} alt={``}
                                                                                 src={cursorImage} onClick={() => {
                                              clickLock('pointer', `${dir}_${i.value}.${name}`);
                                            }}/></div> : null
                                        }
                                      </Form.Item>
                                    </div>
                                  )
                                  }
                                </Form.Item>
                                {
                                  isAdd ? (
                                    <div style={{width: '50px'}}>
                                      <PlusCircleOutlined
                                        onClick={() => {
                                          add({
                                            type: '__page',
                                            fieldType: i.type,
                                            field: i.value,
                                          }, name + 1)
                                        }}
                                      />
                                      {
                                        fields.length > 1 && <MinusCircleOutlined onClick={() => remove(name)}/>
                                      }
                                    </div>
                                  ) : null
                                }
                              </div>
                            </Form.Item>
                            {subTb && isOpenSubTab &&
                            <div className='subTbAttrWrapper'>
                              {i.childrenNode.map((j: any) => {
                                let options: any[] = getSubTableAttrOptions(labelKey, name);
                                return (
                                  <Form.Item label={j.label} key={j.label} {...formItemLayout}>
                                    <div style={{display: 'flex', alignItems: 'center', marginBottom: '10px'}}>
                                      <MappingIcon/>
                                      <Form.Item className='subTbAttr' {...restField} name={[name, `${j.value}`]}>
                                        <Cascader className='subTbAttrSel' placeholder='请选择父节点所选组件的子元素' options={options}
                                                allowClear/>
                                      </Form.Item>
                                    </div>
                                  </Form.Item>
                                )
                              })}
                            </div>
                            }
                          </>
                        )
                      })}
                    </>
                  )}

                </Form.List>
              )
            })
          }
        </Form>
      </FormWrapper>
    )
  }

  //初始化时，设置表单值
  useEffect(() => {
    let newMapping = _.cloneDeep(mapping);
    let newFormData: any = {};
    if (!_.isEmpty(mapping)) {
      newMapping.map((i: any) => {
        newFormData[`${dir}_${i.value}`] = i.children ? i.children.map((j: any) => {
          return {
            type: '__page',
            fieldType: i.type,
            field: i.value,
            pageValue: '',
            pageLabel: '',
            contextValue: '',
            constantValue: '',
            ...j,
          }
        }) : [{
          type: '__page',
          fieldType: i.type,
          field: i.value,
          pageValue: '',
          pageLabel: '',
          contextValue: '',
          constantValue: '',
          childrenNode: i.childrenNode
        }]
      })
    }
    form.setFieldsValue(newFormData);
    cacheSaveParams && cacheSaveParams(dir, newFormData);
  }, [mapping]);
  //选择右侧的组件时，写入左边的框
  const handleFormData = (obj: any, pathStr: string, key: string, value: any) => {
    let _path: any = pathStr.split('.');
    let last: any = _path.pop();
    _path = _path.join('.');
    last = parseInt(last, 10);
    obj[_path][last][key] = value;
    return obj;
  }

  const setSubTableAttr = (formData: any, formPath: any = '', widgetId: any = null, isClear:boolean = false) => {
    if (formPath && formPath.indexOf('.') > 0) {
      let pathArr = formPath.split('.');
      let key = pathArr[0];
      let idx = parseInt(pathArr[1]);
      if (formData[key] && formData[key][idx]) {
        let item: any = formData[key][idx];
        if (item.fieldType == 'o2m' && (widgetId && widgetId != item.pageValue) || isClear) {
          let widgetName = item.pageLabel.split('.')[0];
          let keys: any = Object.keys(item);
          keys.forEach((k: any) => {
            if (['children', 'field', 'fieldType', 'pageLabel', 'pageValue', 'type', 'constantValue', 'contextValue', 'childrenNode', 'children'].indexOf(k) == -1
              && (item[k] && item[k].startsWith && !item[k].startsWith(widgetName) || !widgetName)) {
              item[k] = null;
            }
          });
        }
      }
    }
  }

  useEffect(() => {
    if (myTaskMappingStatus && myTaskMappingState.isChecked) {
      const {path, key, widgetName, widgetLabel, widgetId, widgetType} = myTaskMappingState;
      let formData = form.getFieldsValue();
      if (key == dir) {
        //console.log('+++++++++++++++++++', widgetId, widgetName, path);
        //_.set(formData, `${path}.pageValue`, `${widgetId}`);
        //_.set(formData, `${path}.pageLabel`, `${widgetName.split('.')[0]}${widgetLabel?('.'+widgetLabel):''}`);
        let pageValue = widgetId,
          pageLabel = `${widgetName.split('.')[0]}${widgetLabel ? ('.' + widgetLabel) : ''}`;
        if (widgetType == 'SUB_TABLE_WIDGET') {
          pageLabel = widgetName;
        }

        formData = handleFormData(formData, path, 'pageValue', pageValue);
        formData = handleFormData(formData, path, 'pageLabel', pageLabel);

        setSubTableAttr(formData, myTaskMappingState.path, myTaskMappingState.widgetId);
        form.setFieldsValue(formData);
        cacheSaveParams && cacheSaveParams(dir, formData);
      }
      //写入映射，并关闭焦点
      dispatchMyTaskMapping({
        isChecked: false
      });
      dispatchMyTaskMappingStatus(false);
      //重置鼠标为默认图标
      changeCursor('default');
    }
  }, [myTaskMappingState]);

  const clearFormPage = (path: string) => {
    let formData = form.getFieldsValue();
    formData = handleFormData(formData, path, 'pageValue', '');
    formData = handleFormData(formData, path, 'pageLabel', '');
    //_.set(formData, `${path}.pageValue`, ``);
    //_.set(formData, `${path}.pageLabel`, ``);

    setSubTableAttr(formData, path, null, true);
    form.setFieldsValue(formData);
    cacheSaveParams && cacheSaveParams(dir, formData);
  }
  return (
    <>
      <Collapse collapsible="header" className='x-task-mappingEdit' defaultActiveKey={['1']} ghost
                expandIconPosition={`end`}>
        {/* <Panel header={<Header />} key="1" extra={<RightText />}> */}
        <Panel header={<Header/>} key="1">
          {
            service ?
              (
                _.isEmpty(mapping) ?
                  (<EmptyComponent des={`该服务中无输出参数`}/>)
                  :
                  (<DataList/>)
              )
              :
              (<EmptyComponent des={`暂无数据，请先选择服务`}/>)
          }
        </Panel>
      </Collapse>
    </>
  )
}

export default MappingEdit;
